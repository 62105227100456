import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
// import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import WhyUs from "../components/Repeating/WhyUs";
import IndustriesWeServe from "../components/Repeating/IndustriesWeServe";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import Bio from "../components/Repeating/Bio";

const Page = ({ data }) => {
  return (
    <Layout
      headerStyle="standard"
      headerLinkColor="dark"
      headerHasBorder={false}
    >
      <SearchEngineOptimization
        title="About Ryan Franklin | Painted Rhino"
        description="Learn about Ryan Franklin and how he launched his company, Painted Rhino. With 30 years of experience, Ryan and his family have developed an award-winning fabrication enterprise. Read more!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <Bio className="pt-20 md:pt-32 mb-20 md:mb-28" />

      <CTABackgroundImage />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/About_FB.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/About_Twitter.jpg" }
    ) {
      publicURL
    }
    aboutUsDesktop: file(relativePath: { eq: "about/about-us-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    aboutUsMobile: file(relativePath: { eq: "about/about-us-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    faith: file(relativePath: { eq: "about/faith.svg" }) {
      publicURL
    }
    family: file(relativePath: { eq: "about/family.svg" }) {
      publicURL
    }
    customerService: file(relativePath: { eq: "about/customer-service.svg" }) {
      publicURL
    }
  }
`;

export default Page;
